import React from 'react';
import OurWorkProjectComponent from '../component/OurWork/ourWorkProjectComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';
import BlogHomeComponent from '../component/Blog/blogHomeComponent';
import OurWorkImage from '../images/our-work_banner.png';
import Layout from '../component/layout';
const OurWork = props => {
  const {
    data: {
      wpPage: {
        seo,
        homePageHeaderSection: {
          homeHeaderTitle,
          homeHeaderDescription,
          homeHeaderLearnButton,
        },
        ourWorkAgreedSection: {
          ourWorkAgreedDescription,
          ourWorkAgreedListItem,
          ourWorkAgreedListTitle,
          ourWorkAgreedSubtitle,
          ourWorkAgreedTitle,
          ourWorkAgreedImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkAgreedImage },
            },
          },
        },
        ourWorkHyraSection: {
          ourWorkHyraDescription,
          ourWorkHyraListItem,
          ourWorkHyraListTitle,
          ourWorkHyraSubtitle,
          ourWorkHyraTitle,
          ourWorkHyraImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkHyraImage },
            },
          },
        },
        ourWorkNaxxaSection: {
          ourWorkNaxxaDescription,
          ourWorkNaxxaListItem,
          ourWorkNaxxaListTitle,
          ourWorkNaxxaSubtitle,
          ourWorkNaxxaTitle,
          ourWorkNaxxaImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkNaxxaImage },
            },
          },
        },
        ourWorkOneclickupSection: {
          ourWorkOneclickupDescription,
          ourWorkOneclickupListItem,
          ourWorkOneclickupListTitle,
          ourWorkOneclickupSubtitle,
          ourWorkOneclickupTitle,
          ourWorkOneclickupImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkOneclickupImage },
            },
          },
        },
        ourWorkPurposeSection: {
          ourWorkPurposeDescription,
          ourWorkPurposeListItem,
          ourWorkPurposeListTitle,
          ourWorkPurposeSubtitle,
          ourWorkPurposeTitle,
          ourWorkPurposeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkPurposeImage },
            },
          },
        },
        ourWorkBashSection: {
          ourWorkBashDescription,
          ourWorkBashListItem,
          ourWorkBashListTitle,
          ourWorkBashSubtitle,
          ourWorkBashTitle,
          ourWorkBashImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkBashImage },
            },
          },
        },
        ourWorkParamountSection: {
          ourWorkParamountDescription,
          ourWorkParamountListItem,
          ourWorkParamountListTitle,
          ourWorkParamountSubtitle,
          ourWorkParamountTitle,
          ourWorkParamountImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkParamountImage },
            },
          },
        },
        ourWorkWedaxSection: {
          ourWorkWedaxDescription,
          ourWorkWedaxListItem,
          ourWorkWedaxListTitle,
          ourWorkWedaxSubtitle,
          ourWorkWedaxTitle,
          ourWorkWedaxImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkWedaxImage },
            },
          },
        },
        ourWorkRakutenSection: {
          ourWorkRakutenSectionDescription,
          ourWorkRakutenSectionTitle,
          ourWorkRakutenSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: ourWorkRakutenSectionImage },
            },
          },
        },
        ourWorkRenovationSection: {
          ourWorkRenovationSectionTitle,
          ourWorkRenovationSectionDescription,
          ourWorkRenovationSectionImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: ourWorkRenovationSectionImage,
              },
            },
          },
        },
        ourWorkCleanClinicSection: {
          ourWorkCleanClinicSectionTitle,
          ourWorkCleanClinicSectionDescription,
          ourWorkCleanClinicSectionImage: {
            localFile: {
              childImageSharp: {
                gatsbyImageData: ourWorkCleanClinicSectionImage,
              },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout seo={seo} title={title}>
      <BlogHomeComponent
        blogTitle={homeHeaderTitle}
        blogDescription={homeHeaderDescription}
        ourWorkImage={OurWorkImage}
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone={ourWorkAgreedTitle}
        ourWorkProjectTitle={ourWorkAgreedSubtitle}
        ourWorkProjectDescription={ourWorkAgreedDescription}
        ourWorkProjectSubtitle={ourWorkAgreedListTitle}
        ourWorkProjectButton={homeHeaderLearnButton}
        ourWorkProjectList={ourWorkAgreedListItem}
        isGray={true}
        projectImage={ourWorkAgreedImage}
        links='/agreed'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone={ourWorkOneclickupTitle}
        ourWorkProjectTitle={ourWorkOneclickupSubtitle}
        ourWorkProjectDescription={ourWorkOneclickupDescription}
        ourWorkProjectSubtitle={ourWorkOneclickupListTitle}
        ourWorkProjectButton={homeHeaderLearnButton}
        ourWorkProjectList={ourWorkOneclickupListItem}
        //  isGray={true}
        projectImage={ourWorkOneclickupImage}
        links='/oneclickapp'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone={ourWorkPurposeTitle}
        ourWorkProjectTitle={ourWorkPurposeSubtitle}
        ourWorkProjectDescription={ourWorkPurposeDescription}
        ourWorkProjectSubtitle={ourWorkPurposeListTitle}
        ourWorkProjectList={ourWorkPurposeListItem}
        ourWorkProjectButton={homeHeaderLearnButton}
        isGray={true}
        projectImage={ourWorkPurposeImage}
        links='/purposolver'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone={ourWorkHyraTitle}
        ourWorkProjectTitle={ourWorkHyraSubtitle}
        ourWorkProjectDescription={ourWorkHyraDescription}
        ourWorkProjectSubtitle={ourWorkHyraListTitle}
        ourWorkProjectList={ourWorkHyraListItem}
        ourWorkProjectButton={homeHeaderLearnButton}
        //  isGray={true}
        projectImage={ourWorkHyraImage}
        links='/hyra'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone={ourWorkNaxxaTitle}
        ourWorkProjectTitle={ourWorkNaxxaSubtitle}
        ourWorkProjectDescription={ourWorkNaxxaDescription}
        ourWorkProjectSubtitle={ourWorkNaxxaListTitle}
        ourWorkProjectList={ourWorkNaxxaListItem}
        ourWorkProjectButton={homeHeaderLearnButton}
        isGray={true}
        projectImage={ourWorkNaxxaImage}
        links='/naxxa'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone='Bash'
        ourWorkProjectTitle={ourWorkNaxxaSubtitle}
        ourWorkProjectDescription={ourWorkBashDescription}
        ourWorkProjectSubtitle={ourWorkBashListTitle}
        ourWorkProjectList={ourWorkBashListItem}
        ourWorkProjectButton={homeHeaderLearnButton}
        //  isGray={true}
        projectImage={ourWorkBashImage}
        links='/bash'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone='Paramount'
        ourWorkProjectTitle={ourWorkNaxxaSubtitle}
        ourWorkProjectDescription={ourWorkParamountDescription}
        ourWorkProjectSubtitle={ourWorkParamountListTitle}
        ourWorkProjectList={ourWorkParamountListItem}
        ourWorkProjectButton={homeHeaderLearnButton}
        isGray={true}
        projectImage={ourWorkParamountImage}
        links='/paramount'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone='Wedax'
        ourWorkProjectTitle={ourWorkNaxxaSubtitle}
        ourWorkProjectDescription={ourWorkWedaxDescription}
        ourWorkProjectSubtitle={ourWorkWedaxListTitle}
        ourWorkProjectList={ourWorkWedaxListItem}
        ourWorkProjectButton={homeHeaderLearnButton}
        //  isGray={true}
        projectImage={ourWorkWedaxImage}
        links='/wedax'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone={ourWorkRakutenSectionTitle}
        ourWorkProjectDescription={ourWorkRakutenSectionDescription}
        ourWorkProjectButton={homeHeaderLearnButton}
        isGray={true}
        projectImage={ourWorkRakutenSectionImage}
        links='/rakuten'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone='Clean Clinic'
        ourWorkProjectDescription={ourWorkCleanClinicSectionDescription}
        ourWorkProjectButton={homeHeaderLearnButton}
        //  isGray={true}
        projectImage={ourWorkCleanClinicSectionImage}
        links='/clean-clinic'
      />
      <OurWorkProjectComponent
        ourWorkProjectTitleIcone={ourWorkRenovationSectionTitle}
        ourWorkProjectDescription={ourWorkRenovationSectionDescription}
        ourWorkProjectButton={homeHeaderLearnButton}
        isGray={true}
        projectImage={ourWorkRenovationSectionImage}
        links='/welcome-renovation'
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "ourwork" }) {
      seo {
        metaDesc
        title
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderLearnButton
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkAgreedSection {
        ourWorkAgreedDescription
        ourWorkAgreedListItem
        ourWorkAgreedListTitle
        ourWorkAgreedSubtitle
        ourWorkAgreedTitle
        ourWorkAgreedImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkBashSection {
        ourWorkBashDescription
        ourWorkBashListItem
        ourWorkBashListTitle
        ourWorkBashSubtitle
        ourWorkBashTitle
        ourWorkBashImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkHyraSection {
        ourWorkHyraDescription
        ourWorkHyraListItem
        ourWorkHyraListTitle
        ourWorkHyraSubtitle
        ourWorkHyraTitle
        ourWorkHyraImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkNaxxaSection {
        ourWorkNaxxaDescription
        ourWorkNaxxaListItem
        ourWorkNaxxaListTitle
        ourWorkNaxxaSubtitle
        ourWorkNaxxaTitle
        ourWorkNaxxaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkOneclickupSection {
        ourWorkOneclickupDescription
        ourWorkOneclickupListItem
        ourWorkOneclickupListTitle
        ourWorkOneclickupSubtitle
        ourWorkOneclickupTitle
        ourWorkOneclickupImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkParamountSection {
        ourWorkParamountDescription
        ourWorkParamountListItem
        ourWorkParamountListTitle
        ourWorkParamountSubtitle
        ourWorkParamountTitle
        ourWorkParamountImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkPurposeSection {
        ourWorkPurposeDescription
        ourWorkPurposeListItem
        ourWorkPurposeListTitle
        ourWorkPurposeSubtitle
        ourWorkPurposeTitle
        ourWorkPurposeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkWedaxSection {
        ourWorkWedaxDescription
        ourWorkWedaxListItem
        ourWorkWedaxListTitle
        ourWorkWedaxSubtitle
        ourWorkWedaxTitle
        ourWorkWedaxImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkRakutenSection {
        ourWorkRakutenSectionDescription
        ourWorkRakutenSectionTitle
        ourWorkRakutenSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkRenovationSection {
        ourWorkRenovationSectionTitle
        ourWorkRenovationSectionDescription
        ourWorkRenovationSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      ourWorkCleanClinicSection {
        ourWorkCleanClinicSectionTitle
        ourWorkCleanClinicSectionDescription
        ourWorkCleanClinicSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default OurWork;
