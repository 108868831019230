
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg'
const OurWorkProjectComponent = (props) =>{
    const {
        ourWorkProjectTitleIcone,
        ourWorkProjectTitle,
        ourWorkProjectDescription,
        ourWorkProjectSubtitle,
        ourWorkProjectButton,
        isGray,
        ourWorkProjectList,
        projectImage,
        links
    } = props;
    return(
        <div className={`ourWork-project-section ${isGray ? 'ourWork-project-section-gray' : ''}`}>
            <div className="full-container">
            <div className="home-section-textIcone">
                        <TitleIcone className="home-section-titleIcone"/>
                        <h2 className="ourWork-project-section-text-title">{ourWorkProjectTitleIcone}</h2>
                  </div>
                  <div className="ourWork-project-section-text">
                <div className="row">
                    <div className="col-md-6">
                        <GatsbyImage  image={projectImage} className="ourWork-project-section-image" alt="project"/>
                    </div>
                    <div className="col-md-6 ourWork-project-section-text-col">
                        <p className="ourWork-project-section-title">{ourWorkProjectTitle}</p>
                        <p className="ourWork-project-section-description" dangerouslySetInnerHTML={{ __html: ourWorkProjectDescription }}></p>
                        <p className="ourWork-project-section-subtitle">{ourWorkProjectSubtitle}</p>
                        <p className="ourWork-project-section-description" dangerouslySetInnerHTML={{ __html: ourWorkProjectList }}></p>
                        <Link className="ourWork-project-section-button" to={links}>{ourWorkProjectButton}</Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
export default OurWorkProjectComponent;